import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { classToPlain, plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type MaybeArray<S> = S | S[] | void;

type Constructor<T> = new () => T;

interface Options {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };

    observe?: 'body';

    params?: HttpParams | {
        [param: string]: string | string[];
    };

    reportProgress?: boolean;

    responseType?: 'json';

    withCredentials?: boolean;

    serialize?: boolean;

    deserialize?: boolean;
}

function serialize(data: unknown | null, options: Options) {
    return data && false !== options.serialize
        ? classToPlain(data)
        : data;
}

function deserialize<T extends MaybeArray<S>, S>(data: unknown, clazz: Constructor<S>,
    options: Options): T {
    if (!data || !clazz || false === options.serialize) {
        return data as T;
    }

    return plainToClass(clazz, data) as T;
}

export class SerializingHttpClient {

    constructor(private http: HttpClient, private apiUrl: string) {
    }

    get<T extends MaybeArray<S>, S>(clazz: Constructor<S>, path: string,
        options?: Options): Observable<T> {
        options = options || {};
        options.headers = options.headers || {};

        if (!!clazz && false !== options.deserialize && !options.headers['Accept']) {
            options.headers['Accept'] = 'application/json';
        }

        return this.http
            .get(this.apiUrl + path, options)
            .pipe(map((data) => deserialize(data, clazz, options)));
    }

    put<T extends MaybeArray<S>, S>(clazz: Constructor<S>, path: string, body: unknown | null,
        options?: Options): Observable<T> {
        options = options || {};
        options.headers = options.headers || {};

        if (false !== options.serialize && !options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
        }

        if (!!clazz && false !== options.deserialize && !options.headers['Accept']) {
            options.headers['Accept'] = 'application/json';
        }

        const json = serialize(body, options);

        return this.http
            .put(this.apiUrl + path, json, options)
            .pipe(map((data) => deserialize(data, clazz, options)));
    }

    post<T extends MaybeArray<S>, S>(clazz: Constructor<S>, path: string, body: unknown | null,
        options?: Options): Observable<T> {
        options = options || {};
        options.headers = options.headers || {};

        if (false !== options.serialize && !options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
        }

        if (!!clazz && false !== options.deserialize && !options.headers['Accept']) {
            options.headers['Accept'] = 'application/json';
        }

        const json = serialize(body, options);

        return this.http
            .post(this.apiUrl + path, json, options)
            .pipe(map((data) => deserialize(data, clazz, options)));
    }

    delete<T extends MaybeArray<S>, S>(clazz: Constructor<S>, path: string,
        options?: Options): Observable<T> {
        options = options || {};
        options.headers = options.headers || {};

        if (!!clazz && false !== options.deserialize && !options.headers['Accept']) {
            options.headers['Accept'] = 'application/json';
        }

        return this.http
            .delete(this.apiUrl + path, options)
            .pipe(map((data) => deserialize(data, clazz, options)));
    }

}
