import { Injectable } from '@angular/core';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { RegionType } from '@app/shared/models/region-type.model';
import { Region } from '@app/shared/models/region.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RegionService {

    constructor(private http: SerializingHttpClient) {
    }

    public getProvinces(): Observable<Region[]> {
        return this.getRegions(RegionType.PROVINCE);
    }

    public getDistricts(province: Region): Observable<Region[]> {
        let parentId = null;
        if (province) {
            parentId = province.id;
        }

        return this.getRegions(RegionType.DISTRICT, parentId);
    }

    public getRegions(type: RegionType, ancestorId: string = null): Observable<Region[]> {
        const params = {
            type: String(type),
        };

        if (ancestorId) {
            params['ancestorId'] = ancestorId;
        }

        return this.http.get(Region, '/regions', {
            params,
        });
    }
}
