import { Injectable } from '@angular/core';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { Event } from '@app/shared/models/event.model';
import { Period } from '@app/shared/models/period';
import { from, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const MAX_PER_PAGE = 100;

async function fetchAllPages<R>(fetchObjects: (page) => Promise<R | R[] | void>): Promise<R[]> {
    const objects = [];
    let page = 1;

    // eslint-disable-next-line no-constant-condition
    while (true) {
        const objectsPage = await fetchObjects(page++);

        if (!Array.isArray(objectsPage)) {
            throw new Error('Expected the object page to be an array');
        }

        objectsPage.forEach((event) => objects.push(event));

        if (objectsPage.length < MAX_PER_PAGE) {
            break;
        }
    }

    return objects;
}

@Injectable({
    providedIn: 'root',
})
export class EventService {

    private hoveredEventId = new Subject<string>();

    private selectedEventId = new Subject<string>();

    constructor(private http: SerializingHttpClient) {
    }

    public getEvents(
        regionId: string,
        eventTypeId: string,
        entityId: string,
        period: Period,
    ): Observable<Event[]> {
        const params = {};
        if (null !== regionId) {
            params['regionId'] = regionId;
        }

        if (null !== eventTypeId) {
            params['eventTypeId'] = eventTypeId;
        }

        if (null !== entityId) {
            params['entityId'] = entityId;
        }

        if (null !== period && null !== period.period) {
            params['from'] = period.getPeriodStart().format('YYYY-MM-DD');
            params['until'] = period.getPeriodEnd().format('YYYY-MM-DD');
        }

        return from(
            fetchAllPages(
                (page) => this.http
                    .get(Event, `/events?page=${page}`, { params })
                    .toPromise(),
            ),
        );
    }

    public get(id: string): Observable<Event> {
        return this.http.get(Event, `/events/${id}`);
    }

    setHoveredEventId(eventId: string): void {
        this.hoveredEventId.next(eventId);
    }

    getHoveredEventId(): Observable<string> {
        return from(this.hoveredEventId).pipe(distinctUntilChanged());
    }

    setSelectedEventId(eventId: string): void {
        this.selectedEventId.next(eventId);

        // force a hover out even if the browser does not trigger it
        this.hoveredEventId.next(null);
    }

    getSelectedEventId(): Observable<string> {
        return from(this.selectedEventId).pipe(distinctUntilChanged());
    }
}
