import { AgmCoreModule } from '@agm/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { environment } from '@app/environments/environment';
import { EventDetailComponent } from '@app/events/components/event-detail/event-detail.component';
import { EventListComponent } from '@app/events/components/event-list/event-list.component';
import { EventRegistrationComponent } from '@app/events/components/event-registration/event-registration.component';
import { EventsModule } from '@app/events/events.module';
import { SharedModule } from '@app/shared/shared.module';

registerLocaleData(localeDe, 'de');

const appRoutes: Routes = [
    {
        path: ':id/register',
        component: EventRegistrationComponent,
    },
    {
        path: ':id',
        component: EventDetailComponent,
    },
    {
        path: '',
        component: EventListComponent,
        pathMatch: 'full',
    },
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        CoreModule,
        SharedModule,
        EventsModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapsApiKey,
        }),
        RouterModule.forRoot(
            appRoutes,
            {
                enableTracing: false,
            },
        ),
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
