import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PeriodUntilPipe } from '@app/shared/pipes/period-until/period-until.pipe';

@NgModule({
    imports: [
        CommonModule,
        AgmCoreModule,
    ],
    exports: [
        AgmCoreModule,
        PeriodUntilPipe,
    ],
    declarations: [
        PeriodUntilPipe,
    ],
})
export class SharedModule {
}
