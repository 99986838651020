<ng-container *ngIf="event">
    <div class="neos-event-registration__title-bar">
        Veranstaltungsanmeldung <span (click)="onClose()">x</span>
    </div>
    <div class="neos-event-registration__header">
        <div class="neos-event-registration__header__date">
            <div class="neos-event-registration__header__date__day">
                {{ event.startTime.getDate() }}<ng-container *ngIf="event.endTime.getDate() !== event.startTime.getDate()">/{{ event.endTime.getDate() }}</ng-container>
            </div>
            <div class="neos-event-registration__header__date__month">{{ (event.startTime | date:'MMM').replace('.', '') }}</div>
        </div>
        <div class="neos-event-registration__header__title">{{ event.title }}</div>
        <div class="neos-event-registration__header__address">{{ event.place.name }}</div>
        <div class="neos-event-registration__header__time">
            {{ event.startTime | periodUntil:event.endTime }}
        </div>
        <div class="neos-event-registration__header__type">{{ event.eventType.name }}</div>
    </div>

    <form #eventAttendeeForm="ngForm"
          (ngSubmit)="onSubmit()"
          *ngIf="!registered"
          class="neos-event-registration__form">
        <div class="form-group form-group--required neos-event-registration__form__salutation">
            <label for="firstName">Anrede</label>
            <div class="form-control-block">
                <label for="salutationHerr">
                    <input [(ngModel)]="eventAttendee.salutation"
                           class="form-control"
                           id="salutationHerr"
                           name="salutation"
                           required
                           type="radio"
                           value="Herr">
                    <span>Herr</span>
                </label>
                <label for="salutationFrau">
                    <input [(ngModel)]="eventAttendee.salutation"
                           class="form-control"
                           id="salutationFrau"
                           name="salutation"
                           required
                           type="radio"
                           value="Frau">
                    <span>Frau</span>
                </label>
                <label for="salutationDivers">
                    <input [(ngModel)]="eventAttendee.salutation"
                           class="form-control"
                           id="salutationDivers"
                           name="salutation"
                           required
                           type="radio"
                           value="Divers">
                    <span>Divers</span>
                </label>
            </div>
        </div>
        <div class="form-group form-group--required neos-event-registration__form__first-name">
            <label for="firstName">Vorname</label>
            <div class="form-control-group">
                <input #firstName="ngModel"
                       [(ngModel)]="eventAttendee.firstName"
                       class="form-control"
                       id="firstName"
                       name="firstName" required
                       type="text">
                <div [hidden]="firstName.valid || !submitted"
                     class="form-error">
                    Bitte gib deinen Vornamen ein.
                </div>
            </div>
        </div>
        <div class="form-group form-group--required neos-event-registration__form__last-name">
            <label for="lastName">Nachname</label>
            <div class="form-control-group">
                <input #lastName="ngModel"
                       [(ngModel)]="eventAttendee.lastName"
                       class="form-control"
                       id="lastName"
                       name="lastName" required
                       type="text">
                <div [hidden]="lastName.valid || !submitted"
                     class="form-error">
                    Bitte gib deinen Nachnamen ein.
                </div>
            </div>
        </div>
        <div class="form-group form-group--required neos-event-registration__form__email">
            <label for="email">E-Mail</label>
            <div class="form-control-group">
                <input #email="ngModel"
                       [(ngModel)]="eventAttendee.email"
                       class="form-control"
                       email id="email"
                       name="email"
                       required>
                <div [hidden]="email.valid || !submitted"
                     class="form-error">
                    Bitte gib deine E-Mail-Adresse ein.
                </div>
            </div>
        </div>
        <div *ngIf="event.guestsPossible" class="form-group neos-event-registration__form__number-of-guests">
            <label for="numberOfGuests">Wieviele Begleiter:innen nimmst du
                mit?</label>
            <div class="form-control-group">
                <input [(ngModel)]="eventAttendee.numberOfGuests"
                       [min]="0"
                       class="form-control"
                       id="numberOfGuests"
                       name="numberOfGuests"
                       type="number">
            </div>
        </div>
        <div class="form-group neos-event-registration__form__notes">
            <label for="notes">Möchtest du weitere Anmerkungen
                hinterlassen?</label>
            <div class="form-control-group">
                <textarea [(ngModel)]="eventAttendee.notes"
                          class="form-control" id="notes"
                          name="notes"></textarea>
            </div>
        </div>
        <div class="form-group form-group--required neos-event-registration__form__gdpr">
            <div class="form-control-group">
                <input #gdpr="ngModel"
                       [(ngModel)]="eventAttendee.gdpr"
                       class="form-control"
                       id="gdpr"
                       name="gdpr"
                       type="checkbox"
                       required>
                <label for="gdpr">
                    <p>
                        Mit der freiwilligen Angabe ihrer Daten erklären Sie Ihre
                        Einwilligung, dass NEOS Ihnen Informationsmaterial oder Einladungen
                        zusenden können. Personenbezogene Daten werden von NEOS
                        ausschließlich nach den Vorgaben des österreichischen
                        Datenschutzgesetzes (DSG) sowie der Datenschutzgrundverordnung
                        erhoben, verarbeitet und genutzt. Eine Weitergabe dieser Daten
                        an Dritte erfolgt grundsätzlich nicht, sofern keine gesetzliche
                        Verpflichtung zur Weitergabe besteht oder die Weitergabe
                        der Strafverfolgung dient.
                    </p>
                    <p>
                        Ihre Daten werden in Form eines “Joint Controllership”
                        von NEOS (Politische Partei, NEOS Lab und NEOS-Klubs/Fraktionen
                        in allgemeinen Vertretungskörpern) sowie JUNOS,
                        JUNOS Studierende und UNOS verarbeitet. Hauptverantwortlich
                        ist dabei NEOS – Das Neue Österreich und Liberales Forum
                        (politische Partei). Weiters nutzen wir Dienstleister,
                        um Ihnen beispielsweise Newsletter oder postalische Zusendungen
                        zukommen zu lassen, Ihre Spenden zu verarbeiten oder unsere
                        Botschaften in den sozialen Medien zu verbreiten. Hierzu
                        übermitteln wir die dazu nötigen Daten an die jeweiligen Dienstleister,
                        die für uns keine Dritten sind. Wir haben gemäß der geltenden
                        rechtlichen Bestimmungen Vorsorge getroffen, dass alle unsere
                        Dienstleister entsprechend sorgsam mit den verwendeten Daten
                        umgehen sowie sämtliche gesetzlichen Bestimmungen einhalten.
                        Dies wird uns auch vertraglich garantiert.
                    </p>
                    <p>
                        Wir tun alles, um Ihre Daten zu schützen. Dazu zählen Maßnahmen,
                        um die Manipulation, den Verlust, die Zerstörung oder den
                        Zugriff durch unberechtigte Personen zu verhindern. Dazu
                        setzen wir technische, organisatorische sowie rechtliche
                        Rahmenbedingungen. Alle Daten werden auf unseren Servern
                        bzw. auf den Servern unserer Dienstleister, mit denen Verträge
                        zur Auftragsdatenverarbeitung nach § 10 (DSG) mit entsprechender
                        Prüfung abgeschlossen wurden, gespeichert. Alle unsere Systeme
                        sind samt berechtigten Nutzergruppen in einem Verarbeitungsverzeichnis
                        erfasst und detailliert beschrieben. Wir speichern die Daten
                        maximal drei Jahre ab dem letzten aktiven Kontakt - bzw. bis auf Widerruf.
                    </p>
                    <p>
                        Sämtliche Betroffenenrechte (Auskunftsrecht, Berichtigungsrecht,
                        Widerspruchsrecht, Recht auf Datenübertragbarkeit, Widerspruch
                        oder Löschbegehren) können persönlich an allen Standorten,
                        per Brief oder per Mail an
                        <a href="mailto:datenschutz@neos.eu">datenschutz@neos.eu</a>
                        geltend gemacht werden. Unser Datenschutzbeauftragter,
                        Dr. Karl-Arthur Arlamovsky, ist unter
                        <a href="mailto:dsb@neos.eu">dsb@neos.eu</a> erreichbar.
                        Wenn Sie glauben, dass Ihre Daten unrechtmäßig verarbeitet werden,
                        haben Sie ein Beschwerderecht an die zuständige Aufsichtsbehörde.
                        Dies ist in Österreich die Datenschutzbehörde
                        <a href="https://www.dsb.gv.at">www.dsb.gv.at</a>.
                    </p>
                    <p>
                        Weitere Informationen zum Thema Datenschutz finden Sie unter:
                        <a href="https://neos.eu/datenschutz">https://neos.eu/datenschutz</a>
                    </p>
                </label>
                <div [hidden]="gdpr.valid || !submitted"
                     class="form-error">
                    Bitte stimme der Verarbeitung deiner Daten zu.
                </div>
            </div>
        </div>
        <div *ngIf="campaignMonitorEnabled" class="form-group neos-event-registration__form__newsletter">
            <div class="form-control-group">
                <input [(ngModel)]="eventAttendee.newsletter"
                       class="form-control"
                       id="newsletter"
                       name="newsletter"
                       type="checkbox">
                <label for="newsletter">Ja, ich möchte den Newsletter per
                    E-Mail erhalten.</label>
            </div>
        </div>

        <button [disabled]="submitting" class="btn btn-success" type="submit">
            Jetzt anmelden
        </button>
        <button (click)="onClose()" type="submit">Abbrechen</button>
    </form>

    <div *ngIf="registered" class="neos-event-registration--registered">
        <div class="neos-event-registration--registered__title">Vielen Dank!
            Deine Anmeldung ist hiermit bestätigt.
        </div>
        <div class="neos-event-registration--registered__text">Du solltest
            innerhalb weniger Minuten ein E-Mail mit weiteren Informationen
            erhalten. Bis Bald!
        </div>
        <button (click)="onClose()">Zurück zur Veranstaltungsliste</button>
    </div>
</ng-container>
