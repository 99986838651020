import { Type } from 'class-transformer';
import { Address } from './address.model';

export class Place {

    readonly id: string;

    readonly name: string;

    @Type(() => Address)
    readonly address: Address;

    readonly latitude: number;

    readonly longitude: number;

    readonly zoomLevel: number;

}
