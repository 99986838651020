import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EventService } from '@app/core/services/event/event.service';
import { environment } from '@app/environments/environment';
import { Event } from '@app/shared/models/event.model';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'neos-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.css'],
    host: { class: 'neos-event-detail' },
})
export class EventDetailComponent implements OnInit, OnDestroy {
    event: Event;
    imageUrl: string = environment.imageUrl;

    calendarUrl: string;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private eventService: EventService,
    ) {
    }

    ngOnInit() {
        this.route.paramMap
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap(
                    (params: ParamMap) => this.eventService.get(params.get('id')),
                ),
            )
            .subscribe(
                (event: Event) => {
                    this.event = event;
                    this.calendarUrl = environment.apiUrl + `/events/${this.event.id}/calendar`;
                },
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onClose(): void {
        this.router.navigate(['']);
    }

    onRegister(event: Event): void {
        this.router.navigate([event.id, 'register']);
    }
}
