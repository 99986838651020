import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { EventFilterService } from '@app/core/services/event-filter/event-filter.service';
import { EventService } from '@app/core/services/event/event.service';
import { environment } from '@app/environments/environment';
import { Event } from '@app/shared/models/event.model';
import { Subject } from 'rxjs';
import {
    debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil,
} from 'rxjs/operators';
import { EventDetailComponent } from '../event-detail/event-detail.component';
import { EventRegistrationComponent } from '../event-registration/event-registration.component';

@Component({
    selector: 'neos-event-map',
    templateUrl: './event-map.component.html',
    styleUrls: ['./event-map.component.css'],
    host: { class: 'neos-event-map' },
})
export class EventMapComponent implements OnInit, OnDestroy {

    markerUrl = environment.baseUrl + '/assets/maps/marker.svg';

    markerSelectedUrl = environment.baseUrl + '/assets/maps/marker-selected.svg';

    latitude = 47.704637;

    longitude = 13.539224;

    events: Event[] = [];

    selectedEventId: string = null;

    hoveredEventId: string = null;

    private ngUnsubscribe = new Subject<void>();

    constructor(private eventService: EventService,
                private filterService: EventFilterService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.filterService.getFilters().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(500),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            switchMap(
                (filters) => this.eventService.getEvents(
                    filters.region ? filters.region.id : null,
                    filters.eventType ? filters.eventType.id : null,
                    filters.entity ? filters.entity.id : null,
                    filters.period,
                ),
            ),
        ).subscribe((events) => {
            this.events = events;
        });

        this.eventService.getHoveredEventId()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((eventId) => this.hoveredEventId = eventId);

        this.eventService.getSelectedEventId()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((eventId) => this.selectedEventId = eventId);

        this.router.events.pipe(
            takeUntil(this.ngUnsubscribe),
            filter((event) => event instanceof ActivationEnd),
            map((event: ActivationEnd) => event.snapshot),
        ).subscribe(
            (snapshot) => {
                switch (snapshot.component) {
                case EventDetailComponent:
                case EventRegistrationComponent:
                    this.eventService.setSelectedEventId(snapshot.params['id']);
                    break;

                default:
                    this.eventService.setSelectedEventId(null);
                }
            },
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onEventSelect(event: Event): void {
        this.router.navigate([event.id]);
    }
}
