import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EntityService } from '@app/core/services/entity/entity.service';
import { EventFilterService } from '@app/core/services/event-filter/event-filter.service';
import { EventTypeService } from '@app/core/services/event-type/event-type.service';
import { RegionService } from '@app/core/services/region/region.service';
import { Entity } from '@app/shared/models/entity.model';
import { EventFilters } from '@app/shared/models/event-filters.model';
import { EventType } from '@app/shared/models/event-type.model';
import { Period } from '@app/shared/models/period';
import { RegionType } from '@app/shared/models/region-type.model';
import { Region } from '@app/shared/models/region.model';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'neos-event-filter',
    templateUrl: './event-filter.component.html',
    styleUrls: ['./event-filter.component.scss'],
    host: { class: 'neos-event-filter' },
})
export class EventFilterComponent implements OnInit, OnDestroy {

    @Input() defaultPeriod: Period;

    @Input() defaultEventType: EventType;

    @Input() defaultEntity: Entity;

    @Input() defaultProvince: Region;

    @Input() defaultDistrict: Region;

    districts: Region[] = [];

    provinces: Region[] = [];

    eventTypes: EventType[] = [];

    periods: Period[] = [];

    entities: Entity[] = [];

    province: Region = null;

    district: Region = null;

    eventType: EventType = null;

    entity: Entity = null;

    period: Period = null;

    private filters: EventFilters;

    private province$ = new BehaviorSubject<Region>(null);

    private district$ = new BehaviorSubject<Region>(null);

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private regionService: RegionService,
        private filterService: EventFilterService,
        private eventTypeService: EventTypeService,
        private entityService: EntityService,
    ) {
    }

    ngOnInit(): void {
        for (const period of Period.ALL) {
            this.periods.push(new Period(period));
        }

        if (null !== this.defaultPeriod && Period.ALL.indexOf(this.defaultPeriod.period) > -1) {
            this.period = this.defaultPeriod;

            this.filterService.changeFilters({
                period: this.defaultPeriod,
            });
        }

        this.regionService.getRegions(RegionType.PROVINCE)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (provinces) => {
                    this.provinces = provinces;

                    if (
                        null !== this.defaultProvince
                        && undefined !== provinces.find((province) => province.id === this.defaultProvince.id)
                    ) {
                        this.province = provinces.find((province) => province.id === this.defaultProvince.id);
                        this.province$.next(this.province);

                        this.filterService.changeFilters({
                            province: this.province,
                        });
                    }
                },
            );

        this.eventTypeService.getEventTypes()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (eventTypes) => {
                    this.eventTypes = eventTypes;

                    if (
                        null !== this.defaultEventType
                        && undefined !== eventTypes.find((eventType) => eventType.id === this.defaultEventType.id)
                    ) {
                        this.eventType = eventTypes.find((eventType) => eventType.id === this.defaultEventType.id);

                        this.filterService.changeFilters({
                            eventType: this.eventType,
                        });
                    }
                },
            );

        this.entityService.getEntities()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((entities) => {
                this.entities = entities;

                if (
                    null !== this.defaultEntity
                    && undefined !== entities.find((entitiy) => entitiy.id === this.defaultEntity.id)
                ) {
                    this.entity = entities.find((entitiy) => entitiy.id === this.defaultEntity.id);

                    this.filterService.changeFilters({
                        entity: this.entity,
                    });
                }
            });

        this.filterService.getFilters()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((filters) => {
                this.filters = filters;
            });

        this.province$.pipe(
            takeUntil(this.ngUnsubscribe),
            switchMap(
                (province) => province
                    ? this.regionService.getRegions(
                        'Wien' === province.name ? RegionType.TOWN : RegionType.DISTRICT,
                        province.id,
                    )
                    : of([]),
            ),
            map((districts) => districts.sort((a, b) => a.sortName.localeCompare(b.sortName))),
        ).subscribe(
            (districts) => {
                this.districts = districts;

                if (
                    null !== this.defaultDistrict
                    && undefined !== districts.find((district) => district.id === this.defaultDistrict.id)
                ) {
                    this.district = districts.find((district) => district.id === this.defaultDistrict.id);
                    this.district$.next(this.district);

                    this.filterService.changeFilters({
                        district: this.district,
                    });
                }
            },
        );

        combineLatest(this.province$, this.district$).pipe(
            takeUntil(this.ngUnsubscribe),
            map(([province, district]) => district ? district : province),
        ).subscribe(
            (region) => {
                this.filterService.changeFilters({ ...this.filters, region });
            },
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onProvinceChange(provinceId: string): void {
        if (null !== this.province && this.province.id === provinceId) {
            return;
        }

        let newProvince = this.provinces.find((province) => province.id === provinceId);

        if (undefined === newProvince) {
            newProvince = null;
        }

        this.province$.next(newProvince);
        this.province = newProvince;

        this.onDistrictChange(null);
    }

    onDistrictChange(districtId: string): void {
        let newDistrict = this.districts.find((district) => district.id === districtId);

        if (undefined === newDistrict) {
            newDistrict = null;
        }

        this.district$.next(newDistrict);
        this.district = newDistrict;
    }

    onEventTypeChange(eventTypeId: string): void {
        const eventType = new EventType(eventTypeId);
        this.filterService.changeFilters({ ...this.filters, eventType });
        this.eventType = eventType;
    }

    onEntityChange(entityId: string): void {
        const entity = new Entity(entityId);
        this.filterService.changeFilters({ ...this.filters, entity });
        this.entity = entity;
    }

    onPeriodChange(rawPeriod: string): void {
        const period = new Period(rawPeriod);
        this.filterService.changeFilters({ ...this.filters, period });
        this.period = period;
    }
}
