<form class="neos-event-filter__form">
    <select (ngModelChange)="onPeriodChange($event)"
            [ngModel]="period?.period"
            [disabled]="0 === periods.length"
            class="neos-event-filter__period"
            name="period">
        <option [ngValue]="null">Alle Termine</option>
        <option *ngFor="let period of periods" [ngValue]="period.period">{{period.period}}</option>
    </select>

    <select (ngModelChange)="onProvinceChange($event)"
            [ngModel]="province?.id"
            [disabled]="0 === provinces.length"
            class="neos-event-filter__province"
            name="province">
        <option [ngValue]="null">Alle Bundesländer</option>
        <option *ngFor="let province of provinces" [ngValue]="province.id">{{province.name}}</option>
    </select>

    <select (ngModelChange)="onDistrictChange($event)"
            [ngModel]="district?.id"
            [disabled]="0 === districts.length"
            class="neos-event-filter__district"
            name="district">
        <option [ngValue]="null">Alle Bezirke</option>
        <option *ngFor="let district of districts" [ngValue]="district.id">{{district.name}}</option>
    </select>

    <select (ngModelChange)="onEntityChange($event)"
            [ngModel]="entity?.id"
            [disabled]="0 === entities.length"
            class="neos-event-filter__entity"
            name="entity">
        <option [ngValue]="null">Alle Organisatoren</option>
        <option *ngFor="let entity of entities" [ngValue]="entity.id">{{entity.name}}</option>
    </select>

    <select (ngModelChange)="onEventTypeChange($event)"
            [ngModel]="eventType?.id"
            [disabled]="0 === eventTypes.length"
            class="neos-event-filter__event-type"
            name="eventType">
        <option [ngValue]="null">Alle Formate</option>
        <option *ngFor="let eventType of eventTypes" [ngValue]="eventType.id">{{eventType.name}}</option>
    </select>
</form>
