<agm-map [fitBounds]="events.length > 0"
         [latitude]="latitude"
         [longitude]="longitude"
         [maxZoom]="19"
         [styles]="[{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }]"
         class="neos-event-map__map">
    <agm-marker (markerClick)="onEventSelect(event)"
                *ngFor="let event of events"
                [agmFitBounds]="true"
                [iconUrl]="{ url: event.id === selectedEventId || event.id === hoveredEventId ? markerSelectedUrl : markerUrl, scaledSize: { height: 24, width: 24 } }"
                [latitude]="event.place.latitude"
                [longitude]="event.place.longitude"
                [ngClass]="{
                    'neos-event-map__marker': true,
                    'neos-event-map__marker--selected': event.id === selectedEventId,
                    'neos-event-map__marker--hovered': event.id === hoveredEventId
                }"></agm-marker>
</agm-map>
