import { Injectable } from '@angular/core';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { EventAttendee } from '@app/shared/models/event-attendee.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EventAttendeeService {

    constructor(private http: SerializingHttpClient) {
    }

    public register(eventId: string, eventAttendee: EventAttendee): Observable<string> {
        return this.http.post(
            // Server responds with "OK"
            null,
            `/events/${eventId}/attendees`,
            eventAttendee,
        );
    }
}
