<div (click)="onCollapse()" class="neos-event-list__collapser {{ expanded ? '' : 'event-list__collapser--hidden' }}">
    Veranstaltungsliste ausblenden
</div>
<div class="neos-event-list__expander {{ expanded ? 'event-list__expander--hidden': '' }}" (click)="onExpand()">
    Veranstaltungsliste einblenden
</div>

<div class="neos-event-list {{ expanded ? '' : 'event-list--hidden' }}">
    <div class="neos-event-list__placeholder" *ngIf="loading">
        Veranstaltungen werden geladen…
    </div>
    <div class="neos-event-list__placeholder" *ngIf="!loading && 0 === events.length">
        Keine zu deinen Filtern passenden Veranstaltungen gefunden.
    </div>
    <div class="neos-event-list__element"
         *ngFor="let event of events"
         (click)="onEventSelect(event)"
         (mouseenter)="onEventHoverIn(event)"
         (mouseleave)="onEventHoverOut()">
        <div class="neos-event-list__element__date">
            <div class="neos-event-list__element__date-day">
                {{ event.startTime.getDate() }}<ng-container *ngIf="event.endTime.getDate() !== event.startTime.getDate()">/{{ event.endTime.getDate() }}</ng-container>
            </div>
            <div class="neos-event-list__element__date-month">{{ (event.startTime | date:'MMM').replace('.', '') }}</div>
        </div>
        <div class="neos-event-list__element__title">{{ event.title }}</div>
        <div class="neos-event-list__element__address">{{ event.place.name }}</div>
        <div class="neos-event-list__element__time">
            {{ event.startTime | periodUntil:event.endTime }}
        </div>
        <div class="neos-event-list__element__type">{{ event.eventType.name }}</div>
    </div>
</div>
