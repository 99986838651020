import { Expose } from 'class-transformer';

export class EventAttendee {

    salutation = 'Herr';

    firstName: string;

    lastName: string;

    email: string;

    numberOfGuests = 0;

    newsletter = false;

    gdpr = false;

    @Expose({ name: 'notes' })
    private _notes: string = null;

    public get notes(): string {
        return this._notes;
    }

    public set notes(notes: string) {
        this._notes = notes || null;
    }
}
