import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { environment } from '@app/environments/environment';

@NgModule({
    imports: [
        HttpClientModule,
    ],
    providers: [
        {
            provide: SerializingHttpClient,
            useFactory: (http: HttpClient) => new SerializingHttpClient(http, environment.apiUrl),
            deps: [[HttpClient]],
        },
    ],
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }

}
