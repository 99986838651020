<ng-container *ngIf="event">
    <div class="neos-event-detail__title-bar">
        Veranstaltung <span (click)="onClose()">x</span>
    </div>
    <div class="neos-event-detail__header">
        <div class="neos-event-detail__image">
            <img src="{{ imageUrl }}{{ event.image ? event.image : event.eventType.image }}"/>
        </div>
        <div class="neos-event-detail__header__date">
            <div class="neos-event-detail__header__date__day">
                {{ event.startTime.getDate() }}<ng-container *ngIf="event.endTime.getDate() !== event.startTime.getDate()">/{{ event.endTime.getDate() }}</ng-container>
            </div>
            <div class="neos-event-detail__header__date__month">{{ (event.startTime | date:'MMM').replace('.', '') }}</div>
        </div>
        <div class="neos-event-detail__header__title">{{ event.title }}</div>
        <div class="neos-event-detail__header__address">{{ event.place.name }}</div>
        <div *ngIf="event.canceled"
             class="neos-event-detail__header__cancellation-info">Diese Veranstaltung wurde abgesagt.</div>
        <div class="neos-event-detail__header__time">
            {{ event.startTime | periodUntil:event.endTime }}
        </div>
        <div class="neos-event-detail__header__type">{{ event.eventType.name }}</div>
        <div class="neos-event-registration__header__ical">
            <a class="neos-event-registration__header__ical__export" [href]="calendarUrl" target="_blank">Termin exportieren (iCal)</a>
        </div>

    </div>
    <div [innerHtml]="event.text" class="neos-event-detail__text"></div>

    <div *ngIf="event.registrationOpen && !event.canceled" class="neos-event-detail__registration">
        <div class="neos-event-detail__registration__title">Anmeldung</div>
        <div *ngIf="!!event.registrationDeadline && event.registrationDeadlineReached; else registrationOpen"
             class="neos-event-detail__registration__deadline-reached">
            Die Anmeldung ist leider vorbei.
        </div>
        <ng-template #registrationOpen>
            <div *ngIf="event.hasFreeSpots(); else noMoreFreeSpots"
                 class="neos-event-detail__registration__free-spots">
                <div *ngIf="!!event.registrationDeadline" class="neos-event-detail__registration__deadline">
                    Bitte melde dich spätestens bis
                    {{ event.registrationDeadline | date:'longDate' }}
                    {{ event.registrationDeadline | date:'shortTime' }}
                    an.
                </div>
                <button (click)="onRegister(event)">Jetzt anmelden</button>
                <ng-container *ngIf="event.spots > 0">
                    noch {{ event.freeSpots }} {{ 1 !== event.freeSpots ? 'Plätze' : 'Platz' }}
                    frei.
                </ng-container>
            </div>
            <ng-template #noMoreFreeSpots>
                <div class="neos-event-detail__registration__spot-limit-reached">
                    Es sind leider keine Plätze mehr frei.
                </div>
            </ng-template>
        </ng-template>
    </div>

    <div *ngIf="event.place" class="neos-event-detail__place">
        <div class="neos-event-detail__place__header">Veranstaltungsort</div>
        <div class="neos-event-detail__place__title">{{ event.place.name }}</div>
        <div class="neos-event-detail__place__street">{{ event.place.address.street }}</div>
        <div class="neos-event-detail__place__street2">{{ event.place.address.street2 }}</div>
        <div class="neos-event-detail__place__city">
            {{ event.place.address.city }}
            {{ event.place.address.postalCode }}
        </div>
    </div>

    <div *ngIf="event.entity" class="neos-event-detail__organizer">
        <div class="neos-event-detail__organizer__header">Organisator</div>
        <div class="neos-event-detail__organizer__title">{{ event.entity.name }}</div>
    </div>
</ng-container>
