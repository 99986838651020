import { dateToString } from '@app/shared/transformers/dateToString';
import { Transform, Type } from 'class-transformer';
import { Entity } from './entity.model';
import { EventType } from './event-type.model';
import { Place } from './place.model';

export class Event {

    readonly id: string;

    readonly title: string;

    @Transform(dateToString)
    readonly startTime: Date;

    @Transform(dateToString)
    readonly endTime: Date;

    readonly image: string;

    readonly text: string;

    readonly registrationOpen: boolean;

    @Transform(dateToString)
    readonly registrationDeadline: Date;

    readonly guestsPossible: boolean;

    readonly spots: number;

    readonly speaker: string;

    readonly liveStream: boolean;

    @Type(() => Place)
    readonly place: Place;

    @Type(() => EventType)
    readonly eventType: EventType;

    @Type(() => Entity)
    readonly entity: Entity;

    readonly freeSpots: number;

    readonly registrationDeadlineReached: boolean;

    readonly canceled: boolean;

    public hasFreeSpots(): boolean {
        // Could be -1 or >0
        return 0 !== this.freeSpots;
    }
}
