import { Injectable } from '@angular/core';
import { Entity } from '@app/shared/models/entity.model';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EntityService {

    getEntities(): Observable<Entity[]> {
        return of([
            new Entity('8b3dec6d-d6eb-42e7-912a-857082fe6eff', 'NEOS Partei'),
            new Entity('af0f6c11-4744-4adb-b2b5-fcd63401df30', 'NEOS Klub'),
            new Entity('301f6729-6bb7-4bbe-a1cf-dd0b186bd28b', 'NEOS Lab'),
            new Entity('53ddae25-8cfd-48e5-9636-3592aebf6990', 'UNOS'),
            new Entity('5911a3a7-b144-4de8-84db-6580d4ef4950', 'JUNOS'),
        ]);
    }

}
