import * as moment from 'moment';

export class Period {

    static TODAY = 'Heute';

    static THIS_WEEK = 'Diese Woche';

    static NEXT_WEEK = 'Nächste Woche';

    static THIS_MONTH = 'Dieser Monat';

    static NEXT_MONTH = 'Nächster Monat';

    static ALL = [
        Period.TODAY,
        Period.THIS_WEEK,
        Period.NEXT_WEEK,
        Period.THIS_MONTH,
        Period.NEXT_MONTH,
    ];

    readonly period: string = null;

    constructor(period: string) {
        if (Period.ALL.indexOf(period) > -1) {
            this.period = period;
        }
    }

    public getPeriodStart(): moment.Moment {
        moment.locale('de');
        const dateStart = moment();

        if (Period.NEXT_WEEK === this.period) {
            dateStart
                .add(1, 'week')
                .startOf('week');
        } else if (Period.NEXT_MONTH === this.period) {
            dateStart
                .add(1, 'month')
                .startOf('month');
        }

        return dateStart;
    }

    public getPeriodEnd(): moment.Moment {
        moment.locale('de');
        const dateEnd = moment();

        if (Period.THIS_WEEK === this.period) {
            dateEnd.endOf('week');
        } else if (Period.NEXT_WEEK === this.period) {
            dateEnd
                .add(1, 'week')
                .endOf('week');
        } else if (Period.THIS_MONTH === this.period) {
            dateEnd.endOf('month');
        } else if (Period.NEXT_MONTH === this.period) {
            dateEnd
                .add(1, 'month')
                .endOf('month');
        }

        return dateEnd;
    }
}
