export class Address {

    readonly street: string;

    readonly street2: string;

    readonly city: string;

    readonly province: string;

    readonly postalCode: string;

    readonly countryCode: string;

}
