import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventFilterService } from '@app/core/services/event-filter/event-filter.service';
import { EventService } from '@app/core/services/event/event.service';
import { Event } from '@app/shared/models/event.model';
import { Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'neos-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.css'],
    host: { class: 'neos-event-list' },
})
export class EventListComponent implements OnInit, OnDestroy {

    events: Event[] = [];

    expanded = true;

    loading = true;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private eventService: EventService,
        private filterService: EventFilterService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.filterService.getFilters().pipe(
            takeUntil(this.ngUnsubscribe),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            tap(() => {
                this.events = [];
                this.loading = true;
            }),
            switchMap(
                (filters) => this.eventService.getEvents(
                    filters.region ? filters.region.id : null,
                    filters.eventType ? filters.eventType.id : null,
                    filters.entity ? filters.entity.id : null,
                    filters.period,
                ),
            ),
        ).subscribe((events) => {
            this.events = events;
            this.loading = false;
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onEventSelect(event: Event): void {
        this.router.navigate([event.id]);
    }

    onEventHoverIn(event: Event): void {
        this.eventService.setHoveredEventId(event.id);
    }

    onEventHoverOut(): void {
        this.eventService.setHoveredEventId(null);
    }

    onExpand(): void {
        this.expanded = true;
    }

    onCollapse(): void {
        this.expanded = false;
    }
}
