import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EventAttendeeService } from '@app/core/services/event-attendee/event-attendee.service';
import { EventService } from '@app/core/services/event/event.service';
import { environment } from '@app/environments/environment';
import { EventAttendee } from '@app/shared/models/event-attendee.model';
import { Event } from '@app/shared/models/event.model';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'neos-event-registration',
    templateUrl: './event-registration.component.html',
    styleUrls: ['./event-registration.component.css'],
    host: { class: 'neos-event-registration' },
})
export class EventRegistrationComponent implements OnInit, OnDestroy {

    @ViewChild('eventAttendeeForm', { static: false }) eventAttendeeForm: NgForm;

    event: Event;

    eventAttendee: EventAttendee = new EventAttendee();

    submitted = false;

    submitting = false;

    registered = false;

    campaignMonitorEnabled = environment.campaignMonitorEnabled;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private eventService: EventService,
        private eventAttendeeService: EventAttendeeService,
    ) {
    }

    ngOnInit() {
        this.route.paramMap
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap((params: ParamMap) => this.eventService.get(params.get('id'))),
            )
            .subscribe((event) => {
                this.event = event;

                if (!event.registrationOpen || event.registrationDeadlineReached || !event.hasFreeSpots()) {
                    this.onClose();
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onClose(): void {
        this.router.navigate([this.event.id]);
    }

    onSubmit() {
        this.submitted = true;

        if (!this.eventAttendeeForm.valid) {
            return;
        }

        this.submitting = true;

        this.eventAttendeeService.register(this.event.id, this.eventAttendee).subscribe(
            () => this.registered = true,
        );
    }

}
