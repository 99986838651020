import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EventDetailComponent } from '@app/events/components/event-detail/event-detail.component';
import { EventFilterComponent } from '@app/events/components/event-filter/event-filter.component';
import { EventListComponent } from '@app/events/components/event-list/event-list.component';
import { EventMapComponent } from '@app/events/components/event-map/event-map.component';
import { EventRegistrationComponent } from '@app/events/components/event-registration/event-registration.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule,
    ],
    exports: [
        EventListComponent,
        EventDetailComponent,
        EventRegistrationComponent,
        EventFilterComponent,
        EventMapComponent,
    ],
    declarations: [
        EventListComponent,
        EventDetailComponent,
        EventRegistrationComponent,
        EventFilterComponent,
        EventMapComponent,
    ],
})
export class EventsModule {
}
