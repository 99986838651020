<neos-event-filter
    [defaultPeriod]="period"
    [defaultEventType]="eventType"
    [defaultEntity]="entity"
    [defaultProvince]="province"
    [defaultDistrict]="district"
></neos-event-filter>

<neos-event-map></neos-event-map>

<router-outlet></router-outlet>