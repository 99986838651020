import { enumToString } from '@app/shared/transformers/enumToString';
import { Expose, Transform } from 'class-transformer';
import { RegionType } from './region-type.model';

export class Region {

    readonly id: string;

    @Expose({ name: 'name' })
    private _name = '';

    @Transform(enumToString(RegionType))
    readonly type: RegionType;

    constructor(id: string = null) {
        this.id = id;
    }

    get name(): string {
        return this._name.replace(/^Wien, /, '');
    }

    get sortName(): string {
        return this.name.replace(
            /\d+/,
            (match) => match.padStart(2, '0'),
        );
    }
}
