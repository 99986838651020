import { Component, ElementRef } from '@angular/core';
import { Entity } from '@app/shared/models/entity.model';
import { EventType } from '@app/shared/models/event-type.model';
import { Period } from '@app/shared/models/period';
import { Region } from '@app/shared/models/region.model';

@Component({
    selector: 'neos-events',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    title = 'event-frontend';
    period: Period;
    eventType: EventType;
    entity: Entity;
    province: Region;
    district: Region;

    constructor(private elementRef: ElementRef) {
        this.period = new Period(this.getAttributeValue('period'));
        this.eventType = new EventType(this.getAttributeValue('event-type'));
        this.entity = new Entity(this.getAttributeValue('entity'));
        this.province = new Region(this.getAttributeValue('province'));
        this.district = new Region(this.getAttributeValue('district'));
    }

    private getAttributeValue(attributeName: string) {
        if (this.elementRef.nativeElement.hasAttribute(attributeName)) {
            return this.elementRef.nativeElement.getAttribute(attributeName).trim();
        }

        return null;
    }
}
