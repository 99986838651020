export const environment = {
    production: true,
    baseUrl: 'https://crm.neos.eu/event-frontend/v1',
    apiUrl: 'https://crm.neos.eu/api/public/v1',
    imageUrl: 'https://crm.neos.eu',
    googleMapsApiKey: 'AIzaSyD4Ho-VwerP31eF1-hzkr6qd4xPZg9WrAE',
    sentryDsn: 'https://289332e8b9f249e99cc0e3440aef0e4a@sentry.webmozarts.com/12',
    sentryRelease: '2go@56.4',
    sentryEnvironment: 'prod',
    campaignMonitorEnabled: true,
};
