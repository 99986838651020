import { Injectable } from '@angular/core';
import { SerializingHttpClient } from '@app/core/http/serializing-http-client';
import { EventType } from '@app/shared/models/event-type.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EventTypeService {

    constructor(private http: SerializingHttpClient) {
    }

    public getEventTypes(): Observable<EventType[]> {
        return this.http.get(EventType, '/event-types?activeOnly=1');
    }

    public get(id: string): Observable<EventType> {
        return this.http.get(EventType, `/event-types/${id}`);
    }

}
