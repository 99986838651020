
export class EventType {

    readonly id: string;

    readonly name: string;

    readonly image: string;

    constructor(id: string = null) {
        this.id = id;
    }

}
