import { Injectable } from '@angular/core';
import { EventFilters, PartialEventFilters } from '@app/shared/models/event-filters.model';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { scan, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EventFilterService {
    public init = false;
    public initEventType = false;
    public initEntity = false;
    public initProvince = false;
    public initDistrict = false;
    private filters = new BehaviorSubject<PartialEventFilters>({});

    private mergedFilters = from(this.filters).pipe(
        scan((filters, updatedFilters) => ({ ...filters, ...updatedFilters }), {
            region: null,
            eventType: null,
            entity: null,
            period: null,
            province: null,
            district: null,
        }),
        shareReplay(1),
    );

    changeFilters(filters: PartialEventFilters) {
        this.filters.next(filters);
    }

    getFilters(): Observable<EventFilters> {
        return this.mergedFilters;
    }
}
